import React, { useState, Fragment } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Grid, FilledInput, InputAdornment, createStyles, makeStyles, Card, Box, Button, CardContent, Typography, Link } from "@material-ui/core";
import { signIn } from "next-auth/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRouter } from "next/router";
import { Lock, Email } from "@material-ui/icons";
import IndexContainer from "../../src/components/index-container";
import ControlledFilledInputIndex from "../../src/components/controlled-filled-input-index";
import { LoadingButton } from "@onpreo/components";
import { $checkIfContactExistInWorkspace } from "../../src/store/thunks/contact.thunk";
import { useAppDispatch } from "../../src/store";
import { GUEST_APP_URL, PROTOCOL } from "../../src/utils/secrets";

const useStyles = makeStyles(theme =>
    createStyles({
        filledInput: {
            width: "100%",
            height: "46px",
            border: "1px solid " + theme.palette.primary.main
        },
        spacer: {
            padding: theme.spacing(1, 2)
        },
        spacerButton: {
            padding: theme.spacing(3, 2, 2)
        },
        loginCard: {
            display: "flex",
            minWidth: 420,
            maxWidth: 450,
            padding: 16,
            backgroundColor: "#F7FAFC"
        },
        cardBackground: {
            display: "flex",
            minHeight: "100vh",
            minWidth: "100%",
            background: "url('/imgs/bgn.jpg') no-repeat",
            backgroundSize: "cover",
            backgroundColor: theme.palette.primary.main + "ee",
            backgroundBlendMode: "multiply"
        },
        linkButton: {
            color: "#5e72e4",
            padding: 0,
            fontSize: 13
        },
        spacerButtonGrid: {
            padding: theme.spacing(1, 2, 2)
        }
    })
);

const loginSchema = yup.object().shape({
    email: yup
        .string()
        .email("Bitte geben Sie eine gültige E-Mail Adresse ein")
        .lowercase("alle Zeichen sollten klein geschrieben werden")
        .required("Eintrag kann nicht leer gelassen werden")
    // password: yup.string().required("Eintrag kann nicht leer gelassen werden")
});

const errors = {
    CredentialsSignin: "Anmeldung fehlgeschlagen. Überprüfen Sie, ob die von Ihnen angegebenen Daten korrekt sind.",
    default: "E-Mail Adresse existiert nicht",
    noPassword: "Registrieren Sie sich zunächst über den Link -"
};

const SignInError = ({ error }) => {
    const errorMessage = error && (errors[error] ?? errors.default);
    return <div>{errorMessage}</div>;
};

export default function Login() {
    const router = useRouter();
    const routerQuery = router.query;
    const { error } = routerQuery;
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const [showMagicLink, setShowMagicLink] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const linkForSignup = `${PROTOCOL}${GUEST_APP_URL}/signup?ws=${routerQuery.ws}`;

    const {
        control,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm({ defaultValues: { email: "", password: "", rememberMe: false }, resolver: yupResolver(loginSchema) });

    const watchPw = watch("password");

    const handleSignin: SubmitHandler<{ email: string; password: string }> = async data => {
        if (routerQuery.ws) {
            const result = await dispatch($checkIfContactExistInWorkspace({ workspaceId: routerQuery.ws as string, email: data.email }));

            if (result?.payload?.status === "warning-email") {
                setErrorMsg("default");
            } else if (result?.payload?.status === "warning-password") {
                setErrorMsg("noPassword");
            } else if (result.payload?.status === "success") {
                setErrorMsg("");
                await signIn("workspace-credentials", {
                    ...data,
                    workspaceId: routerQuery.ws,
                    mode: "workspace-mode",
                    callbackUrl: "/"
                });
            }
        } else await signIn("credentials", { ...data, callbackUrl: "/" });
    };

    const handleSigninByMagicLink: SubmitHandler<{ email: string }> = async data => {
        await signIn("email", { ...data, callbackUrl: "/" });
    };

    return (
        <IndexContainer>
            <Grid container item justifyContent={"space-between"} alignItems={"center"} style={{ height: "80vh", width: "100%" }}>
                <Grid container item xs={6} justifyContent={"center"} direction={"row"} alignItems={"center"} style={{ width: "100%" }}>
                    <Typography align={"center"} variant={"h1"} style={{ width: "100%" }}>
                        Willkommen bei onpreo
                    </Typography>
                    <Typography align={"center"} variant={"h2"}>
                        -- In Kooperation mit Ihrem Immobilienmakler --
                    </Typography>
                </Grid>
                <Grid container xs={6} justifyContent={"center"}>
                    <Box justifyContent={"center"} alignItems={"center"} className={classes.cardBackground}>
                        <Card className={classes.loginCard}>
                            <CardContent>
                                <Grid container justifyContent={"center"}>
                                    <Typography variant={"h2"} align={"center"}>
                                        Schnell und einfach anmelden.
                                    </Typography>
                                    <Typography variant={"h2"} align={"center"}>
                                        Nur mit Ihrer E-Mail-Adresse.
                                    </Typography>
                                </Grid>
                            </CardContent>
                            <form>
                                {showMagicLink ? (
                                    <Fragment>
                                        <div className={classes.spacer}>
                                            <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"}>
                                                <Grid item style={{ width: "100%" }}>
                                                    <Controller
                                                        control={control}
                                                        name="email"
                                                        render={({ field }) => (
                                                            <FilledInput
                                                                {...field}
                                                                className={classes.filledInput}
                                                                placeholder="E-Mail"
                                                                autoComplete="off"
                                                                required
                                                                type="email"
                                                                error={errors.email ? true : false}
                                                                startAdornment={
                                                                    <InputAdornment position="start">
                                                                        <Email />
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item style={{ width: "100%" }}>
                                                    <Typography variant="inherit" color="error">
                                                        {errors.email?.message}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className={classes.spacerButton}>
                                            <Grid container justifyContent={"center"}>
                                                <LoadingButton
                                                    isRedirect={false}
                                                    type="submit"
                                                    variant="contained"
                                                    color={"primary"}
                                                    onClick={handleSubmit(handleSigninByMagicLink)}
                                                >
                                                    Mit E-Mail einloggen
                                                </LoadingButton>
                                            </Grid>
                                        </div>
                                        <div className={classes.spacerButton}>
                                            <Grid container justifyContent={"center"}>
                                                <Button component={Link} style={{ color: "#5e72e4" }} onClick={() => setShowMagicLink(false)}>
                                                    Mit Passwort einloggen
                                                </Button>
                                            </Grid>
                                        </div>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <div className={classes.spacer}>
                                            <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"}>
                                                <Grid item style={{ width: "100%" }}>
                                                    <ControlledFilledInputIndex
                                                        control={control}
                                                        errors={!!errors.email}
                                                        placeholder={"Email"}
                                                        name={"email"}
                                                        required={true}
                                                        icon={<Email />}
                                                    />
                                                </Grid>
                                                <Grid item style={{ width: "100%" }}>
                                                    <Typography variant="inherit" color="error">
                                                        {errors.email?.message}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className={classes.spacer}>
                                            <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"}>
                                                <Grid item style={{ width: "100%" }}>
                                                    <ControlledFilledInputIndex
                                                        control={control}
                                                        errors={!!errors.password}
                                                        placeholder={"Passwort"}
                                                        name={"password"}
                                                        icon={<Lock />}
                                                    />
                                                </Grid>
                                                <Grid item style={{ width: "100%" }}>
                                                    <Typography variant="inherit" color="error">
                                                        {errors.password?.message}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className={classes.spacerButton}>
                                            <Grid container justifyContent={"center"}>
                                                <LoadingButton
                                                    isRedirect={false}
                                                    disabled={!showMagicLink && !watchPw}
                                                    type="submit"
                                                    variant="contained"
                                                    color={"primary"}
                                                    onClick={handleSubmit(handleSignin)}
                                                >
                                                    Mit Passwort einloggen
                                                </LoadingButton>
                                            </Grid>
                                        </div>
                                        <div className={classes.spacerButtonGrid}>
                                            <Grid container justifyContent={"space-between"}>
                                                <Grid item>
                                                    <Button component={Link} className={classes.linkButton} href="/forgot-password">
                                                        Passwort vergessen?
                                                    </Button>
                                                </Grid>
                                                {!routerQuery.ws && (
                                                    <Grid item>
                                                        <Button component={Link} className={classes.linkButton} onClick={() => setShowMagicLink(true)}>
                                                            Ohne Passwort einloggen
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </div>
                                    </Fragment>
                                )}

                                <Typography variant="inherit" align={"center"} color="error">
                                    {error && <SignInError error={error} />}
                                    {errorMsg && <SignInError error={errorMsg} />}
                                    {errorMsg === "noPassword" && (
                                        <div>
                                            <Link href={linkForSignup} target="_blank" rel="noopener" style={{ color: "inherit", textAlign: "center" }}>
                                                Guest-App signup Link
                                            </Link>
                                        </div>
                                    )}
                                </Typography>
                            </form>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </IndexContainer>
    );
}

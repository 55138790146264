import React, { FC } from "react";
import { Box, useTheme } from "@material-ui/core";
import { IndexNavbar, StaticFooter } from "@onpreo/components";

const IndexContainer: FC = props => {
    const theme = useTheme();

    return (
        <Box
            style={{
                backgroundColor: "#F7FAFC"
            }}
        >
            <IndexNavbar logo={{ imgSrc: "/logo.png", imgAlt: "onpreo" }} />
            {props.children}
            <StaticFooter dark={true} />
        </Box>
    );
};

export default IndexContainer;

import { Controller } from "react-hook-form";
import { ButtonBase, FilledInput } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import React, { FC, ReactNode, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles(theme =>
    createStyles({
        filledInput: {
            width: "100%",
            height: "46px",
            marginTop: theme.spacing(2)
        }
    })
);

interface ControlledFilledInputIndexProps {
    control: any;
    errors: boolean;
    placeholder: string;
    type?: string;
    name: string;
    required?: boolean;
    icon: ReactNode;
    disabled?: boolean;
}

const ControlledFilledInputIndex: FC<ControlledFilledInputIndexProps> = ({
    control,
    errors,
    type,
    placeholder,
    name,
    required,
    icon,
    disabled = false
}) => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <FilledInput
                    {...field}
                    placeholder={placeholder}
                    autoComplete="off"
                    className={classes.filledInput}
                    disabled={disabled}
                    required={required}
                    type={showPassword ? "text" : type || name}
                    error={errors}
                    startAdornment={<InputAdornment position={"start"}>{icon}</InputAdornment>}
                    endAdornment={
                        name === "password" && (
                            <InputAdornment position="end" style={{ background: "transparent" }}>
                                <ButtonBase onClick={() => setShowPassword(!showPassword)}>{showPassword ? <Visibility /> : <VisibilityOff />}</ButtonBase>
                            </InputAdornment>
                        )
                    }
                />
            )}
        />
    );
};

export default ControlledFilledInputIndex;
